import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout';
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const DifferentWorlds = () => {
  const data = useStaticQuery(graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "DifferentWorlds"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
  }
`)
const images = [];
for (let i = 0; i < data.allFile.edges.length; i++) {
  images[i] = getImage(data.allFile.edges[i].node)
}

  return(
        <TwoColumnLayout
        Column1Size="66"
        Column1Content={
          <GridContainer>
            {images.map((image, index) => (
                <ImageGrid1x1 image={image} alt={data.allFile.edges[index].node.name} key={index}/>
            ))}
          </GridContainer>

        }
        Column2Size="33"
        Column2Content={
          <TextContainer>
          <Seo title="Different Worlds" />
          <Header siteTitle="Jake Mu" />
          <h2>
            <i>Different Worlds</i>
            <br /><br />
            {'Different Worlds examines the exacerbating change between human and non-human relationships. As the world develops, alongside the digital age, how will design evolve through time? Different Worlds traverses a divergent mentality behind the technological design, looking towards nature as a catalyst for inspiration. The exhibit features various recreations of nature created through algorithms and simultaneous digital production.'}
            <br /><br />
            <b>Flowers</b><br />
            {'Different Worlds began from simply observing flowers. Like humans, flowers are impermanent. Each imperfection establishes their individual character comparable to humans. This piece recreates flowers and their characteristics to manufacture the emotive sensations released from the simple viewing of flowers.'}
            <br /><br />
            <b>Waves</b><br />
            {'Waves recreates the natural phenomena of the ocean. Rolling white peaks are recreated through depth sensors and projection to simulate the motion of water. A sheet is transformed by wind to recreate the mesmerising, unimpeded motion of waves.'}
            <br /><br />
            <b>role:</b><br />
            {'Design and development'}

            <br /><br />
            <b>tech:</b><br />
            {'OpenFrameworks'}

          </h2>
        </TextContainer>

        }
        />
  )
}

export default DifferentWorlds;
